import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';

import { ReactComponent as CloseButton } from 'assets/images/icons/close-button.svg';

interface Props {
  open: boolean;
  onClose: () => void;
}

const VerificationModal = ({ open, onClose }: Props) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          position: 'relative',
          maxWidth: 'sm',
          borderRadius: 2,
          padding: '40px 75px',
          marginTop: '10vh',
          marginLeft: 'auto',
          marginRight: 'auto',
          [theme.breakpoints.down('md')]: {
            padding: '30px 50px',
          },
          [theme.breakpoints.down('sm')]: {
            mt: '5vh',
            padding: '10px 20px',
            ml: '10px',
            mr: '10px',
          },
        }}
      >
        <IconButton
          onClick={onClose}
          size="large"
          sx={{
            position: 'absolute',
            right: '0',
            top: '0',
          }}
        >
          <CloseButton />
        </IconButton>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: theme.palette.primary.main,
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Jesteś certyfikowanym terapeutą?
        </Typography>
        <Typography
          sx={{
            mt: '16px',
            textAlign: 'center',
            fontWeight: '400',
            lineHeight: '24px',
          }}
        >
          W celu uzyskania statusu “Zweryfikowany terapeuta” prześlij na adres:{' '}
          <b>mtg@gmail.com</b> w formie <b>skanu lub zdjęcia</b> swój dyplom
          ukończenia:
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            mt: '16px',
            mb: '40px',
            [theme.breakpoints.down('sm')]: {
              mb: '20px',
            },
          }}
        >
          <ul>
            <li>
              minimum 40-godzinnego kursu Masażu Tkanek Głębokich (niezależnie
              od firmy szkolącej),
            </li>
            <Typography
              sx={{ color: theme.palette.primary.main, fontWeight: '500' }}
            >
              lub
            </Typography>
            <li>
              ukończenia pełnego szkolenia z zakresu Integracji Strukturalnej w
              jednej z wymienionych{' '}
              <Link
                href="https://www.theiasi.net/iasi-recognized-si-training-programs"
                underline="none"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: '500',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                target="_blank"
              >
                tutaj
              </Link>{' '}
              szkół.
            </li>
          </ul>
        </Typography>
        <Divider />
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: theme.palette.primary.main,
            fontSize: '18px',
            textAlign: 'center',
            mt: '40px',
            [theme.breakpoints.down('sm')]: {
              mt: '20px',
            },
          }}
        >
          Nie posiadasz certyfikatu?
        </Typography>
        <Typography
          sx={{
            mt: '16px',
            textAlign: 'center',
            fontWeight: '400',
            lineHeight: '24px',
            mb: '24px',
            [theme.breakpoints.down('sm')]: {
              mb: '20px',
            },
          }}
        >
          Weź udział w kursie, zdobądź certyfikat oraz wiedzę na międzynarodowym
          poziomie i zostań certyfikowanym terapeutą.
        </Typography>
        <Button
          fullWidth
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
            textTransform: 'uppercase',
            padding: '14px',
            borderRadious: 4,
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          onClick={() => {
            const win: Window = window;
            win.location =
              'https://masaztkanekglebokich.pl/szkolenia/kurs-masaz-tkanek-glebokich/';
          }}
        >
          Zapisz się na kurs
        </Button>
      </Box>
    </Modal>
  );
};

export default VerificationModal;
