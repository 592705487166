import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import apiClient from 'services/networking/apiClient';
import { IUser } from 'types/api';

const populate = [
  'subscriptions',
  'subscriptions.plan',
  'subscriptions.payment_histories',
  'physiotherapist',
];

const useUserData = (options: UseQueryOptions<IUser> = {}) =>
  useQuery(
    ['user'],
    async () => {
      const response = await apiClient.get<IUser>('api/users/me', {
        params: {
          populate: populate.join(','),
        },
      });

      return response.data;
    },
    // @ts-ignore
    { ...options },
  );

export default useUserData;
