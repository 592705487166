import { Avatar, Button, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconMenu2, IconPhone, IconCalendar } from '@tabler/icons';
import { pl } from 'date-fns/esm/locale';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LogoSection from 'components/LogoSection/LogoSection';
import ProfileSection from 'components/ProfileSection/ProfileSection';
import VerificationModal from 'components/VerificationModal/VerificationModal';
import VerifiedProfile from 'components/VerifiedProfile/VerifiedProfile';
import useUserData from 'services/hooks/api/useUserData';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import { openSnackbar } from 'store/slices/snackbar';
import { Subscription as SubscriptionType } from 'types/api';

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector(state => state.menu);
  const [freePlan, setFreePlan] = useState<SubscriptionType>();
  const [timeLeft, setTimeLeft] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  const { isSuccess: isFetchedDataSuccess, data: fetchedData } = useUserData();

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (isFetchedDataSuccess) {
      setFreePlan(
        fetchedData.subscriptions?.find(
          sub => sub.plan.Type === 'initial' && sub.Status === 'Aktywna',
        ),
      );
    }
  }, [fetchedData, isFetchedDataSuccess]);

  useEffect(() => {
    if (isFetchedDataSuccess && fetchedData.physiotherapist?.isVerified) {
      setIsVerified(fetchedData.physiotherapist?.isVerified);
    }
  }, [fetchedData, fetchedData?.physiotherapist.isVerified]);

  useEffect(() => {
    if (freePlan) {
      const dueDate = new Date(freePlan.dueDate);
      setTimeLeft(formatDistanceToNow(dueDate, { locale: pl }));
    }
  }, [freePlan]);

  const navigateToTrainings = () => {
    window.open('https://masaztkanekglebokich.pl/szkolenia/', '_blank');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText('+48 662 310 264');
    dispatch(
      openSnackbar({
        open: true,
        message: 'Numer kontaktowy został skopiowany do schowka',
        variant: 'alert',
        alert: {
          color: 'info',
        },
        close: false,
      }),
    );
  };

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            border: '1px solid',
            borderColor: theme.palette.grey[100],
            background: theme.palette.background.paper,
            color: theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.background.paper,
            },
            [theme.breakpoints.up('lg')]: {
              display: 'none',
            },
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="20px" />
        </Avatar>
      </Box>
      {freePlan && timeLeft && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2.5,
            backgroundColor: theme.palette.primary.main,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: 2.5,
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          }}
        >
          <Typography
            sx={{ color: theme.palette.background.paper, fontWeight: 500 }}
          >
            {`Twój bezpłatny plan wygasa za ${timeLeft}.`}
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
              boxShadow: 'none',
              borderRadius: 3.75,
              paddingLeft: 2,
              paddingRight: 2,
              '&:hover': {
                bgcolor: 'transparent',
                color: theme.palette.background.paper,
              },
            }}
            onClick={() => navigate('/cennik')}
          >
            PRZEDŁUŻ SUBSKRYBCJĘ
          </Button>
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            border: '1px solid',
            borderColor: theme.palette.grey[100],
            background: theme.palette.background.paper,
            color: theme.palette.secondary.dark,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.background.paper,
            },
          }}
          onClick={copyToClipboard}
          color="inherit"
        >
          <IconPhone stroke={1.5} size="20px" />
        </Avatar>
        <Typography
          sx={{
            color: theme.palette.dark[900],
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          }}
        >
          Pomoc
        </Typography>
      </Box>

      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            border: '1px solid',
            borderColor: theme.palette.grey[100],
            background: theme.palette.background.paper,
            color: theme.palette.secondary.dark,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.background.paper,
            },
          }}
          onClick={navigateToTrainings}
          color="inherit"
        >
          <IconCalendar stroke={1.5} size="20px" />
        </Avatar>
        <Typography
          sx={{
            color: theme.palette.dark[900],
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          }}
        >
          Szkolenia
        </Typography>
      </Box>

      <VerifiedProfile onClick={handleOpenModal} isVerified={isVerified} />

      <ProfileSection />

      <VerificationModal open={isOpenModal} onClose={handleCloseModal} />
    </>
  );
};

export default Header;
