import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'services/hooks/useAuth';
import { GuardProps } from 'types';

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, isProfileCompleted } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', { replace: true });
    } else if (!isProfileCompleted) {
      navigate('uzupelnij-profil', { replace: true });
    }
  }, [isLoggedIn, isProfileCompleted, navigate]);

  return children;
};

export default AuthGuard;
