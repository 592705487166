import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme, Theme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import DRAWER_WIDTH from 'constants/drawerWidth';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

const excludedPathnames = ['uzupelnij-profil', 'rejestracja-zakonczona'];

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: -(DRAWER_WIDTH - 20),
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        padding: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        padding: '16px',
        marginRight: '10px',
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    }),
  }),
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector(state => state.menu);

  React.useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
  }, [matchDownMd]);

  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    [],
  );

  if (excludedPathnames.some(path => location.pathname.includes(path))) {
    return <Outlet />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        {header}
      </AppBar>

      {/* drawer */}
      <Sidebar />

      {/* main content */}
      <Main theme={theme} open={drawerOpen}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
