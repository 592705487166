import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/slices/menu';
import { NavItemType } from 'types';

interface NavItemProps {
  item: NavItemType;
}

const NavItem = ({ item }: NavItemProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const { selectedItem } = useSelector(state => state.menu);

  const Icon = item?.icon!;

  const listItemProps: {
    component:
      | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
      | string;
    href?: string;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url!} target="_self" />
    )),
  };

  const itemHandler = (id: string) => {
    dispatch(activeItem([id]));
    matchesSM && dispatch(openDrawer(false));
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex(id => id === item.id);
    if (currentIndex > -1) {
      dispatch(activeItem([item.id!]));
    }
    // eslint-disable-next-line
    }, [pathname]);

  return (
    <ListItemButton
      {...listItemProps}
      sx={{
        borderRadius: '8px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: 'inherit',
        py: 1.25,
        pl: '24px',
      }}
      selected={selectedItem?.findIndex(id => id === item.id) > -1}
      onClick={() => itemHandler(item.id!)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
        <Icon stroke={1.5} size="20px" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={
              selectedItem?.findIndex(id => id === item.id) > -1
                ? 'h5'
                : 'body1'
            }
            color="inherit"
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NavItem;
