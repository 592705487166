import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import Loadable from 'components/Loadable/Loadable';
import NavMotion from 'layout/NavMotion/NavMotion';
import GuestGuard from 'utils/route-guard/GuestGuard';

const AuthLogin = Loadable(lazy(() => import('components/Login/Login')));
const AuthRegister = Loadable(
  lazy(() => import('components/Register/Register')),
);
const AuthForgotPassword = Loadable(
  lazy(() => import('components/ForgotPassword/ForgotPassword')),
);
const GoogleAuthCallback = Loadable(
  lazy(() => import('components/GoogleAuthCallback/GoogleAuthCallback')),
);

const LoginRoutes = {
  element: (
    <NavMotion>
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/rejestracja',
      element: <AuthRegister />,
    },
    {
      path: '/zapomniales-hasla',
      element: <AuthForgotPassword />,
    },
    {
      path: '/auth/google',
      element: <GoogleAuthCallback />,
    },
  ],
};

export default LoginRoutes;
