import { lazy } from 'react';

import Loadable from 'components/Loadable/Loadable';
import MainLayout from 'layout/MainLayout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Dashboard = Loadable(lazy(() => import('layout/Dashboard/Dashboard')));

const YourPlan = Loadable(lazy(() => import('components/YourPlan/YourPlan')));

const PaymentHistory = Loadable(
  lazy(() => import('components/PaymentHistory/PaymentHistory')),
);

const Pricing = Loadable(lazy(() => import('components/Pricing/Pricing')));

const RegistrationDone = Loadable(
  lazy(() => import('components/RegistrationDone/RegistrationDone')),
);

const FormWizard = Loadable(
  lazy(() => import('components/FormWizard/FormWizard')),
);

const Extras = Loadable(lazy(() => import('components/Extras/Extras')));

const VodPlatform = Loadable(
  lazy(() => import('components/VodPlatform/VodPlatform')),
);

const VodPlaylist = Loadable(
  lazy(() => import('components/VodPlaylist/VodPlaylist')),
);

const Appointments = Loadable(
  lazy(() => import('components/Appointments/Appointments')),
);

const MyAvailability = Loadable(
  lazy(() => import('components/MyAvailability/MyAvailability')),
);

const DashboardRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: '/uzupelnij-profil',
      element: <FormWizard />,
    },
    {
      path: '/rejestracja-zakonczona',
      element: <RegistrationDone />,
    },
    {
      path: '/twoj-plan',
      element: <YourPlan />,
    },
    {
      path: '/historia-platnosci',
      element: <PaymentHistory />,
    },
    {
      path: '/cennik',
      element: <Pricing />,
    },
    {
      path: '/dostepnosc',
      element: <MyAvailability />,
    },
    {
      path: '/twoje-dodatki',
      element: <Extras />,
    },
    {
      path: '/platforma-vod',
      element: <VodPlatform />,
    },
    {
      path: '/moje-wizyty',
      element: <Appointments />,
    },
    {
      path: '/platforma-vod/:id',
      element: <VodPlaylist />,
    },
  ],
};

export default DashboardRoutes;
