import { Box, Drawer, List, useMediaQuery, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import LogoSection from 'components/LogoSection/LogoSection';
import NavItem from 'components/NavItem/NavItem';
import DRAWER_WIDTH from 'constants/drawerWidth';
import useLandingData from 'services/hooks/api/useLandingData';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import menuItems from 'utils/menuItems';

interface SidebarProps {
  window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector(state => state.menu);

  const { isLoading, isError, data } = useLandingData();

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
    ),
    [],
  );

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <List>
          {menuItems.items.map(item => (
            <NavItem key={item.id} item={item} />
          ))}
        </List>
        {isLoading || isError ? null : (
          <Grid
            sx={{
              position: 'absolute',
              bottom: '3.4rem',
              left: '2.6rem',
              fontSize: '12px',
              color: '#000',
              '& span': {
                textTransform: 'uppercase',
              },
              '& a': {
                color: '#000',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            }}
          >
            <LogoSection />
            <p
              style={{
                marginTop: '1.125rem',
                marginBottom: '0.1rem',
              }}
            >
              <span>tel: </span>
              <a href={`tel:${data.data.attributes.footer_phone}`}>
                {data.data.attributes.footer_phone}
              </a>
            </p>
            <p style={{ marginTop: '0' }}>
              <span>email: </span>
              <a href={`mailto:${data.data.attributes.footer_mail}`}>
                {data.data.attributes.footer_mail}
              </a>
            </p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a
                href={data?.data.attributes.footer_pp.data.attributes.url || ''}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: 'pointer' }}
              >
                Polityka prywatności
              </a>
              <a
                href={
                  data?.data.attributes.footer_regulamin.data.attributes.url ||
                  ''
                }
                rel="noreferrer"
                target="_blank"
              >
                Regulamin
              </a>
            </div>
          </Grid>
        )}
      </PerfectScrollbar>
    ),
    [matchUpMd, data],
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? DRAWER_WIDTH : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerOpen && logo}
        {drawerOpen && drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
