import React, { createContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader/Loader';
import apiClient from 'services/networking/apiClient';
import { useDispatch } from 'store';
import { setRegistrationEmail, setUser, unsetUser } from 'store/slices/user';
import { DefaultRootStateProps } from 'types';
import { JWTContextType } from 'types/auth';
import { UserProfile, UserProfileDto } from 'types/main/user';
import { setSession, verifyToken } from 'utils/session';

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  const state = useSelector((s: DefaultRootStateProps) => s.user);

  const updateProfile = useCallback(async () => {
    const populateFields = [
      'PaymentData',
      'physiotherapist.services',
      'physiotherapist.specializations',
      'physiotherapist.Social',
      'physiotherapist.AdditionalInfo',
      'physiotherapist.Schedule',
      'physiotherapist.languages',
      'physiotherapist.country',
      'physiotherapist.diseases',
      'physiotherapist.Photo',
      'physiotherapist.Office',
      'physiotherapist.Certificates',
      'physiotherapist.schedule_exceptions',
      'physiotherapist.appointments',
      'physiotherapist.city',
    ];
    const userResponse = await apiClient.get<UserProfile>(
      `/api/users/me?populate=${populateFields.join(',')}`,
    );
    const user = userResponse.data;

    dispatch(setUser(user));
  }, [dispatch]);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          updateProfile();
        } else {
          dispatch(unsetUser());
          setSession(null);
        }
      } catch (err) {
        console.error(err);
        dispatch(unsetUser());
        setSession(null);
      }
    };

    init();
  }, [dispatch, updateProfile]);

  const login = async (email: string, password: string) => {
    const response = await apiClient.post<{ jwt: string; user: UserProfile }>(
      '/api/auth/local',
      {
        identifier: email,
        password,
      },
    );
    const { jwt } = response.data;
    setSession(jwt);
    updateProfile();
  };

  const googleHandler = async (accessToken: string) => {
    try {
      const response = await apiClient.get<{ jwt: string; user: UserProfile }>(
        `/api/auth/google/callback?access_token=${accessToken}`,
      );

      const { jwt } = response.data;
      setSession(jwt);
      updateProfile();
    } catch (error) {
      console.error(error);
    }
  };

  const register = async (userDto: UserProfileDto) => {
    const response = await apiClient.post<{ jwt: string; user: UserProfile }>(
      'api/auth/local/register',
      { ...userDto, username: userDto.email },
    );
    const { user } = response.data;
    dispatch(setRegistrationEmail(user.email));
  };

  const logout = () => {
    setSession(null);
    dispatch(unsetUser());
  };

  const forgotPassword = async (email: string) => {
    await apiClient.post('/api/auth/forgot-password', {
      email,
    });
  };

  const resetPassword = async (
    code: string,
    password: string,
    passwordConfirmation: string,
  ) => {
    await apiClient.post('/api/auth/reset-password', {
      code,
      password,
      passwordConfirmation,
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        updateProfile,
        googleHandler,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
