import { LinearProgressProps } from '@mui/material/LinearProgress';
import { Suspense, LazyExoticComponent } from 'react';

import Loader from 'components/Loader/Loader';

interface LoaderProps extends LinearProgressProps {}

const Loadable =
  (Component: LazyExoticComponent<() => JSX.Element>) => (props: LoaderProps) =>
    (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;
