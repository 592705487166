import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import Loadable from 'components/Loadable/Loadable';

const AuthCheckMail = Loadable(
  lazy(() => import('components/CheckMail/CheckMail')),
);
const AuthResetPassword = Loadable(
  lazy(() => import('components/ResetPassword/ResetPassword')),
);
const AccountActivation = Loadable(
  lazy(() => import('components/AccountActivation/AccountActivation')),
);

const AuthenticationRoutes = {
  element: <Outlet />,
  children: [
    {
      path: '/sprawdz-mail',
      element: <AuthCheckMail />,
    },
    {
      path: '/resetuj-haslo',
      element: <AuthResetPassword />,
    },
    {
      path: '/aktywuj-konto',
      element: <AccountActivation />,
    },
  ],
};

export default AuthenticationRoutes;
