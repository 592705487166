import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardProps,
  CardHeaderProps,
  CardContentProps,
  TypographyProps,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { Ref } from 'react';

import { KeyedObject } from 'types';

const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
}

const subheaderTypographyProps: TypographyProps = {
  variant: 'body1',
  color: 'grey.800',
  marginTop: '0.4rem',
};

const MainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      subtitle,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary[200] + 75,
          ':hover': {
            boxShadow: boxShadow
              ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)'
              : 'inherit',
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={title}
            action={secondary}
            subheader={subtitle}
            subheaderTypographyProps={subheaderTypographyProps}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={
              <Typography variant="h3" sx={{ color: theme.palette.grey[900] }}>
                {title}
              </Typography>
            }
            action={secondary}
            subheader={subtitle}
            subheaderTypographyProps={subheaderTypographyProps}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  },
);

export default MainCard;
