import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Snackbar from 'components/Snackbar/Snackbar';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import NavigationScroll from 'layout/NavigationScroll/NavigationScroll';
import Routes from 'routes';
import ThemeCustomization from 'themes';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeCustomization>
        <NavigationScroll>
          <AuthProvider>
            <>
              <Routes />
              <Snackbar />
            </>
          </AuthProvider>
        </NavigationScroll>
      </ThemeCustomization>
    </QueryClientProvider>
  );
};

export default App;
