import { createSlice } from '@reduxjs/toolkit';

import TAX from 'constants/tax';
import { DefaultRootStateProps } from 'types';
import { ProductCardProps } from 'types/cart';
import { Address } from 'types/checkout';

import { dispatch } from '../index';

const initialState: DefaultRootStateProps['cart'] = {
  error: null,
  checkout: {
    products: [],
    subtotal: 0,
    tax: 0,
    total: 0,
    discount: 0,
    discountCode: '',
    billing: null,
    paymentMethod: 'card',
  },
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD PRODUCT
    addProductSuccess(state, action) {
      const total = action.payload.price;
      const tax = Math.round((total * TAX + Number.EPSILON) * 100) / 100;
      const subtotal = total - tax;

      state.checkout.products = [
        {
          ...action.payload,
        },
      ];
      state.checkout.subtotal = subtotal;
      state.checkout.tax = tax;
      state.checkout.total = total;
      state.checkout.discount = 0;
    },

    addDiscountSuccess(state, action) {
      state.checkout.discount = state.checkout.discount + action.payload;
      state.checkout.total = state.checkout.total - state.checkout.discount;
    },

    addCodeSuccess(state, action) {
      state.checkout.discountCode = action.payload;
    },

    // SET BILLING ADDRESS
    setBillingAddressSuccess(state, action) {
      state.checkout.billing = {
        ...action.payload,
      };
    },

    // SET PAYMENT METHOD
    setPaymentMethodSuccess(state, action) {
      state.checkout.paymentMethod = action.payload;
    },

    // RESET CART
    resetCartSuccess(state) {
      state.checkout = initialState.checkout;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addProduct(product: ProductCardProps) {
  return dispatch(slice.actions.addProductSuccess(product));
}

export function setBillingAddress(address: Address | null) {
  return dispatch(slice.actions.setBillingAddressSuccess(address));
}

export function setPaymentMethod(method: string) {
  return dispatch(slice.actions.setPaymentMethodSuccess(method));
}

export function resetCart() {
  return dispatch(slice.actions.resetCartSuccess());
}

export function addDiscount(discount: number) {
  return dispatch(slice.actions.addDiscountSuccess(discount));
}

export function addCode(code: string) {
  return dispatch(slice.actions.addCodeSuccess(code));
}
