import { useRoutes } from 'react-router-dom';

import AuthenticationRoutes from './AuthenticationRoutes';
import DashboardRoutes from './DashboardRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

export default function ThemeRoutes() {
  return useRoutes([
    AuthenticationRoutes,
    LoginRoutes,
    DashboardRoutes,
    MainRoutes,
  ]);
}
