import jwtDecode from 'jwt-decode';

import apiClient from 'services/networking/apiClient';

export const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    apiClient.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete apiClient.defaults.headers.common.Authorization;
  }
};

export const verifyToken: (st: string) => boolean = serviceToken => {
  if (!serviceToken) {
    return false;
  }
  const decoded: { exp: number } = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};
