import { Box, Button, Typography, useTheme } from '@mui/material';

import { ReactComponent as IconGreen } from 'assets/images/icons/verified-green.svg';
import { ReactComponent as IconRed } from 'assets/images/icons/verified-red.svg';

interface Props {
  isVerified: boolean;
  onClick: () => void;
}

const VerifiedProfile = ({ isVerified, onClick }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ml: 2,
        mr: 3,
        [theme.breakpoints.down('md')]: {
          mr: 2,
        },
      }}
    >
      {isVerified ? (
        <Box
          sx={{
            background: theme.palette.background.paper,
            border: '1px solid',
            borderColor: '#4ED173 !important',
            padding: '8px 15px',
            borderRadius: 3,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            color: theme.palette.success.main,
            [theme.breakpoints.down('sm')]: {
              padding: '5px 5px',
              minWidth: 'unset',
            },
          }}
        >
          <IconGreen
            style={{
              color: '#4ED173 !important',
            }}
          />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '20px',
              color: '#4ED173 !important',
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            Profil zweryfikowany
          </Typography>
        </Box>
      ) : (
        <Button
          startIcon={<IconRed />}
          sx={{
            background: theme.palette.background.paper,
            border: '1px solid',
            padding: '5px 15px',
            borderRadius: 3,
            display: 'flex',
            gap: '6px',
            [theme.breakpoints.down('sm')]: {
              padding: '5px 5px',
              minWidth: 'unset',
              marginLeft: 'unset !important',
              marginRight: 'unset !important',
            },
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '20px',
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            Zweryfikuj profil
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default VerifiedProfile;
