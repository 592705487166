import { createSlice } from '@reduxjs/toolkit';

import { DefaultRootStateProps } from 'types';

const initialState: DefaultRootStateProps['user'] = {
  isInitialized: false,
  isLoggedIn: false,
  isProfileCompleted: false,
  error: null,
  profile: null,
  registrationEmail: '',
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.profile = action.payload;
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.isProfileCompleted = action.payload?.isProfileCompleted;
    },
    unsetUser(state) {
      state.profile = null;
      state.isLoggedIn = false;
      state.isInitialized = true;
    },
    setRegistrationEmail(state, action) {
      state.registrationEmail = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { setUser, hasError, unsetUser, setRegistrationEmail } =
  slice.actions;
