import { useQuery } from '@tanstack/react-query';

import apiClient from 'services/networking/apiClient';
import { ILanding } from 'types/api';

const useLandingData = () =>
  useQuery(['landing'], async () => {
    const response = await apiClient.get<ILanding>('api/landing', {
      params: {
        populate: 'footer_pp, footer_regulamin',
      },
    });

    return response.data;
  });

export default useLandingData;
