import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Logo from 'components/Logo/Logo';
import { DASHBOARD_PATH } from 'config';

const LogoSection = () => (
  <Link component={RouterLink} to={DASHBOARD_PATH}>
    <Logo />
  </Link>
);

export default LogoSection;
