import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartReducer from './slices/cart';
import menuReducer from './slices/menu';
import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';

const reducer = combineReducers({
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'fizjo-',
    },
    cartReducer,
  ),
  user: userReducer,
  menu: menuReducer,
});

export default reducer;
