import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import Loadable from 'components/Loadable/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Order = Loadable(lazy(() => import('components/Order/Order')));
const OrderComplete = Loadable(
  lazy(() => import('components/OrderComplete/OrderComplete')),
);

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: '/zamowienie',
      element: <Order />,
    },
    {
      path: '/podsumowanie-zamowienia',
      element: <OrderComplete />,
    },
  ],
};

export default MainRoutes;
