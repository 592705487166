import {
  IconDashboard,
  IconBrowser,
  IconNotes,
  IconStar,
  IconDeviceAnalytics,
  IconClipboardList,
  IconUserCheck,
  IconBasket,
  IconPictureInPicture,
  IconReceipt2,
  IconKey,
  IconCalendarEvent,
} from '@tabler/icons';

import { NavItemType } from 'types';

const icons = {
  IconDashboard,
  IconBrowser,
  IconNotes,
  IconStar,
  IconDeviceAnalytics,
  IconClipboardList,
  IconUserCheck,
  IconBasket,
  IconPictureInPicture,
  IconReceipt2,
  IconKey,
  IconCalendarEvent,
};

const menuItems: { items: NavItemType[] } = {
  items: [
    {
      id: 'default',
      title: 'Ustawienia konta',
      type: 'item',
      url: '/',
      icon: icons.IconDashboard,
    },
    {
      id: 'your-plan',
      title: 'Twój plan',
      type: 'item',
      url: '/twoj-plan',
      icon: icons.IconBrowser,
    },
    {
      id: 'payment-history',
      title: 'Historia płatności',
      type: 'item',
      url: '/historia-platnosci',
      icon: icons.IconNotes,
    },
    {
      id: 'pricing',
      title: 'Cennik',
      type: 'item',
      url: '/cennik',
      icon: icons.IconReceipt2,
    },
    {
      id: 'my-availability',
      title: 'Moja dostępność',
      type: 'item',
      url: '/dostepnosc',
      icon: icons.IconCalendarEvent,
    },
    {
      id: 'my-appointments',
      title: 'Moje wizyty',
      type: 'item',
      url: '/moje-wizyty',
      icon: icons.IconNotes,
    },
    {
      id: 'your-extras',
      title: 'Twoje dodatki',
      type: 'item',
      url: '/twoje-dodatki',
      icon: icons.IconStar,
    },
  ],
};

export default menuItems;
